import { Fragment } from "react";
import { Disclosure, Menu, Transition } from "@headlessui/react";
import { useNavigate } from "react-router-dom";
import {
  Bars3Icon,
  BellIcon,
  XMarkIcon,
  HandRaisedIcon,
  StarIcon,
} from "@heroicons/react/24/outline";
import { PlusIcon, Bars2Icon } from "@heroicons/react/20/solid";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Navbar() {
  const navigate = useNavigate();
  return (
    <Disclosure as="nav" className="bg-black z-50">
      {({ open }) => (
        <>
          <div className={`mx-auto max-w-7xl px-4 sm:px-6 lg:px-8`}>
            <div className="flex h-16 justify-between">
              <div className="flex">
                <div className="-ml-2 mr-2 flex items-center md:hidden">
                  {/* Mobile menu button */}
                  <Disclosure.Button className="relative inline-flex items-center justify-center rounded-md p-2 text-white hover:bg-orange-100 hover:text-orange-200 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-orange-500">
                    <span className="absolute -inset-0.5" />
                    <span className="sr-only">Open main menu</span>
                    {open ? (
                      <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                    ) : (
                      <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                    )}
                  </Disclosure.Button>
                </div>
                <div
                  className="flex flex-shrink-0 items-center hover:cursor-pointer"
                  onClick={() => navigate("/")}
                >
                  <img
                    className="h-8 w-auto"
                    src={require("../Assets/img/logo-white.png")}
                    alt="EmprendeYa logo"
                  />
                </div>
                <div className="hidden md:ml-6 md:flex md:space-x-8">
                  {/* Current: "border-orange-500 text-orange-900", Default: "border-transparent text-white hover:border-orange-300 hover:text-orange-200" */}
                  <a
                    href="/"
                    className="inline-flex items-center border-b-2 border-orange-500 px-1 pt-1 text-sm font-medium text-white"
                  >
                    Home
                  </a>
                  {/* <a
                    href="/home#team"
                    className="inline-flex items-center border-b-2 border-transparent px-1 pt-1 text-sm font-medium text-white hover:border-orange-300 hover:text-orange-200"
                  >
                    Team
                  </a> */}
                  <a
                    href="/#programs"
                    className="inline-flex items-center border-b-2 border-transparent px-1 pt-1 text-sm font-medium text-white hover:border-orange-300 hover:text-orange-200"
                  >
                    Proyectos 
                    
              
                  </a>
                  {/* <a
                    href="/home#schools"
                    className="inline-flex items-center border-b-2 border-transparent px-1 pt-1 text-sm font-medium text-white hover:border-orange-300 hover:text-orange-200"
                  >
                    Schools
                  </a> */}
                </div>
              </div>
              <div className="flex items-center">
                <div className="flex-shrink-0">
                  <button
                    type="button"
                    onClick={() => navigate("/donate")}
                    className="relative inline-flex items-center gap-x-1.5 rounded-md bg-orange-200 px-3 py-2 text-sm  text-orange-800 shadow-sm hover:bg-orange-100 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-orange-600"
                  >
                    <HandRaisedIcon
                      className="-ml-0.5 h-4 w-4"
                      aria-hidden="true"
                    />
                    Donate
                  </button>
                </div>
                <div className="hidden md:ml-4 md:flex md:flex-shrink-0 md:items-center">
                  <button
                    type="button"
                    onClick={() => navigate("/nominate")}
                    className="relative inline-flex items-center gap-x-1.5 rounded-md bg-orange-600 px-3 py-2 text-sm  text-orange-100 shadow-sm hover:bg-orange-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-orange-600"
                  >
                    <StarIcon className="-ml-0.5 h-4 w-4" aria-hidden="true" />
                    Nominate
                  </button>

                  {/* Profile dropdown */}
                  <Menu as="div" className="relative ml-3">
                    <div>
                      <Menu.Button className="relative flex rounded-full p-2 bg-orange-100 text-orange-700 hover:bg-orange-200 text-sm focus:outline-none focus:ring-2 focus:ring-orange-500 focus:ring-offset-2">
                        <span className="text-center" />
                        <span className="sr-only">Open user menu</span>
                        <Bars2Icon className="h-4 w-4" aria-hidden="true" />
                      </Menu.Button>
                    </div>
                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-200"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                        <Menu.Item>
                          {({ active }) => (
                            <a
                              href="/contact"
                              className={classNames(
                                active ? "bg-orange-100" : "",
                                "block px-4 py-2 text-sm text-orange-700"
                              )}
                            >
                              Contact us
                            </a>
                          )}
                        </Menu.Item>
                        <Menu.Item>
                          {({ active }) => (
                            <a
                              href="/donate"
                              className={classNames(
                                active ? "bg-orange-100" : "",
                                "block px-4 py-2 text-sm text-orange-700"
                              )}
                            >
                              Donate{" "}
                            </a>
                          )}
                        </Menu.Item>
                      </Menu.Items>
                    </Transition>
                  </Menu>
                </div>
              </div>
            </div>
          </div>

          <Disclosure.Panel className="md:hidden bg-white">
            <div className="space-y-1 pb-3 pt-2">
              {/* Current: "bg-orange-50 border-orange-500 text-orange-700", Default: "border-transparent text-white hover:bg-orange-50 hover:border-orange-300 hover:text-orange-200" */}
              <Disclosure.Button
                as="a"
                href="/home"
                className="block border-l-4 border-orange-500 bg-orange-50 py-2 pl-3 pr-4 text-base font-medium text-orange-700 sm:pl-5 sm:pr-6"
              >
                Home
              </Disclosure.Button>
              {/* <Disclosure.Button
                as="a"
                href="/home#/team"
                className="block border-l-4 border-transparent py-2 pl-3 pr-4 text-base font-medium text-orange-900 hover:border-orange-300 hover:bg-orange-50 hover:text-orange-200 sm:pl-5 sm:pr-6"
              >
                Team
              </Disclosure.Button> */}
              <Disclosure.Button
                as="a"
                href="/#programs"
                className="block border-l-4 border-transparent py-2 pl-3 pr-4 text-base font-medium text-orange-900 hover:border-orange-300 hover:bg-orange-50 hover:text-orange-200 sm:pl-5 sm:pr-6"
              >
                Proyectos
              </Disclosure.Button>
              {/* <Disclosure.Button
                as="a"
                href="/home#schools"
                className="block border-l-4 border-transparent py-2 pl-3 pr-4 text-base font-medium text-orange-900 hover:border-orange-300 hover:bg-orange-50 hover:text-orange-200 sm:pl-5 sm:pr-6"
              >
                Schools
              </Disclosure.Button> */}
            </div>
            <div className="border-t border-orange-200 pb-3 pt-4">
              <div className="flex items-center px-4 sm:px-6"></div>
              <div className="mt-3 space-y-1">
                <Disclosure.Button
                  as="a"
                  href="/contact"
                  className="block px-4 py-2 text-base font-medium text-orange-900 hover:bg-orange-100 hover:text-orange-800 sm:px-6"
                >
                  Contact us
                </Disclosure.Button>
                <Disclosure.Button
                  as="a"
                  href="/donate"
                  className="block px-4 py-2 text-base font-medium text-orange-900 hover:bg-orange-100 hover:text-orange-800 sm:px-6"
                >
                  Donate
                </Disclosure.Button>
              </div>
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
}
