import React from "react";
import Section from "../components/templates/Section";
import Logo from "../components/Logo";
import { useNavigate } from "react-router-dom";
const heroImage = require("../Assets/img/MainImg.JPG");
const sectionImg = require("../Assets/img/class.jpeg");
const studentsVideo = require("../Assets/img/students.mp4");

const Home = () => {
  const navigate = useNavigate();

  return (
    <div className="max-w-screen bg-black overflow-hidden">
      <div className=" flex flex-col font-sans text-center font-normal overflow-clip text-black">
        <Section className="z-0" id={1}>
          <img
            className="absolute top-0 left-0 object-cover w-full h-full z-0 "
            src={heroImage}
            autoPlay
            muted
            playsInline
            preload="auto"
          />
          <div className="absolute top-0 left-0 flex flex-col items-center justify-center w-full h-full bg-black bg-opacity-50 z-10 animate-slow-appear">
            <div className="sm:text-6xl text-4xl text-white font-sans animate-slide-in-bottom transition-all">
              Los líderes se <span className="font-bold">construyen aquí</span>
              <span className="text-sm">&copy;</span>
            </div>
          </div>
        </Section>

        {/* Mission */}
        <Section id={2} className="bg-black justify-center py-16">
          <div className="flex flex-col gap-4 text-white text-3xl font-bold animate-slow-appear">
            <div className="flex justify-center">
              <img
                src={require("../Assets/img/flag.png")}
                className="h-12  hover:h-16 transition-all duration-500"
              />
            </div>
            Misión
          </div>

          <div className="sm:text-2xl text-xl text-white sm:p-8 p-4 lg:w-4/6 w-3/4">
            En Creando Futuros Brillantes, creemos que el éxito comienza con la
            inspiración desde una edad temprana. Nos dedicamos a conectar a los
            jóvenes de Azua con oportunidades para un futuro prometedor,
            fomentando liderazgo y espíritu emprendedor. {" "}
            <span className="font-bold text-black bg-orange-100 border-orange-600 border-b-2  px-2">
              Mediante seminarios y eventos, ofrecemos un espacio para
              desarrollar ideas innovadoras que generen un impacto positivo.
            </span>{" "}
            Colaboramos con organizaciones afines para apoyar proyectos
            emprendedores que promuevan el progreso y bienestar de la comunidad.
          </div>
        </Section>

        {/* Vision */}
        <Section id={3} className="bg-white justify-center py-16">
          <div className="flex flex-col gap-4 text-orange-950 text-3xl font-bold animate-slow-appear">
            <div className="flex justify-center">
              <img
                src={require("../Assets/img/bullseye.png")}
                className="h-12 hover:h-16 transition-all duration-500"
              />
            </div>
            Visión
          </div>

          <div className="sm:text-2xl text-xl  sm:p-8 p-4 lg:w-4/6 w-3/4">
            Nos visualizamos como catalizadores del desarrollo y transformación
            de la juventud en Azua. Aspiramos a una comunidad vibrante donde
            cada joven alcance su máximo potencial y se convierta en líder y
            agente de cambio.{" "}
            <span className="font-bold text-black bg-orange-100 border-orange-600 border-b-2  px-2">
              Queremos impulsar la innovación, el espíritu emprendedor y el
              liderazgo mediante colaboraciones, construyendo juntos un futuro
              más brillante y prometedor.
            </span>{" "}
          </div>
        </Section>

        {/* Question */}
        <Section className="z-0 relative" id={5}>
          <video
            className="absolute top-0 left-0 object-cover w-full h-full z-0 animate-slow-appear"
            src={studentsVideo}
            autoPlay
            muted
            playsInline
            preload="auto"
            loop
          />
          <div className="absolute top-0 left-0 flex flex-col items-center justify-center w-full h-full bg-black bg-opacity-50 z-10 animate-slow-appear">
            <div className="sm:text-6xl text-4xl text-white font-sans animate-slide-in-bottom transition-all">
              ¿Quién, <span className="font-bold">si no tú?</span>
              <span className="text-sm">&copy;</span>
            </div>
          </div>
        </Section>

        {/* Created for */}
        <Section className="flex flex-col justify-center items-center">
          <div className="flex flex-col gap-4  animate-slow-appear">
            <div className="text-white text-3xl font-bold">Creado para</div>

            <div>
              <ul className="flex flex-wrap justify-center items-center gap-2 text-white text-2xl">
                <li className="py-1 px-2 rounded-md bg-orange-700 hover:bg-orange-800 hover:scale-105 hover:cursor-pointer placeholder:transition-all duration-500">
                  visionarios
                </li>
                <li className="py-1 px-2 rounded-md bg-green-700 hover:bg-green-800 hover:scale-105 hover:cursor-pointer transition-all duration-500">
                  emprendedores
                </li>
                <li className="py-1 px-2 rounded-md bg-slate-700 hover:bg-slate-800 hover:scale-105 hover:cursor-pointer transition-all duration-500">
                  agentes de cambio
                </li>
                <li className="py-1 px-2 rounded-md bg-teal-700 hover:bg-teal-800 hover:scale-105 hover:cursor-pointer transition-all duration-500">
                  soñadores
                </li>
                <li className="py-1 px-2 rounded-md bg-rose-700 hover:bg-rose-800 hover:scale-110 hover:cursor-pointer transition-all duration-500">
                  tú
                </li>
              </ul>
            </div>
          </div>
        </Section>

        {/* Programs */}
        <Section
          id={"programs"}
          className="flex flex-col-reverse h-screen  flex-grow lg:flex-row items-center justify-center  bg-white overflow-hidden"
        >
          <div className="flex flex-col h-full  gap-6 justify-center px-10 sm:px-28 sm:items-start w-full lg:w-1/2 transition-all">
            <div className="text-black sm:text-4xl lg:w-3/4 text-2xl text-start">
              Contenido Centrado en el joven
            </div>
            <div className="flex flex-col gap-2 text-black text-start lg:w-3/4">
              <div>
                <span className="font-bold underline decoration-orange-400 underline-offset-2">
                  Destacar los beneficios
                </span>
                : Creamos contenido que resalte los beneficios tangibles que los
                estudiantes pueden obtener al participar con la organización.
              </div>
              <hr className="opacity-30" />
              <div>
                <span className="font-bold underline decoration-orange-400 underline-offset-2">
                  Historias de Éxito
                </span>
                : Compartimos historias de éxito de estudiantes que han obtenido
                oportunidades dentro del programa.
              </div>
            </div>
            <div className="flex flex-grow-0 justify-start w-full">
              <button
                className="bg-black text-white hover:bg-opacity-80 flex-grow-0 px-8 py-2 rounded-xl mt-4"
                onClick={() => navigate("/nominate")}
              >
                Nominate
              </button>
            </div>
          </div>
          <div className="flex relative w-full lg:w-1/2 h-full justify-center items-center overflow-hidden">
            <div className="absolute inset-0  bg-orange-500 z-10 opacity-30 mix-blend-multiply" />
            <img
              className="absolute inset-0 h-full w-full object-cover"
              src={sectionImg}
            />
          </div>
        </Section>

        {/* Programs 2 */}
        <Section
          id={4}
          className="flex flex-col-reverse h-screen  flex-grow lg:flex-row-reverse items-center justify-center  bg-orange-100 overflow-hidden"
        >
          <div className="flex flex-col h-full  gap-6 justify-center px-10 sm:px-28 sm:items-start w-full lg:w-1/2 transition-all">
            <div className="text-orange-900 sm:text-4xl lg:w-3/4 text-2xl text-start">
              Construcción de Comunidad
            </div>
            <div className="flex flex-col gap-2 text-orange-900 text-start lg:w-3/4">
              <div>
                <span className="font-bold underline decoration-orange-400 underline-offset-2">
                  Mentoría
                </span>
                : Establecemos programas anuales donde los estudiantes pueden
                interactuar con mentores y compañeros
              </div>
              <hr className="opacity-30" />
              <div>
                <span className="font-bold underline decoration-orange-400 underline-offset-2">
                  Eventos
                </span>
                : Formamos parte de seminarios, conferencias, y ferias para
                expandirnos como organización y crecer como comunidad
              </div>
            </div>
            <div className="flex flex-grow-0 justify-start w-full">
              <button
                className="bg-orange-800 text-orange-100 hover:bg-opacity-80 flex-grow-0 px-8 py-2 rounded-xl mt-4"
                onClick={() => navigate("/nominate")}
              >
                ¿Quieres involucrarte?
              </button>
            </div>
          </div>
          <div className="flex relative w-full lg:w-1/2 h-full justify-center items-center overflow-hidden">
            <div className="absolute inset-0  bg-orange-500 z-10 opacity-30 mix-blend-multiply" />
            <img
              className="absolute inset-0 h-full w-full object-cover"
              src={require("../Assets/img/dr-boy.jpeg")}
            />
          </div>
        </Section>

        {/* Programs 3 */}
        <Section
          id={4}
          className="flex flex-col-reverse h-screen  flex-grow lg:flex-row items-center justify-center  bg-orange-950 overflow-hidden"
        >
          <div className="flex flex-col h-full  gap-6 justify-center px-10 sm:px-28 sm:items-start w-full lg:w-1/2 transition-all">
            <div className="text-orange-100 sm:text-4xl lg:w-3/4 text-2xl text-start">
              Oportunidades externas
            </div>
            <div className="flex flex-col gap-2 text-orange-100 text-start lg:w-3/4">
              <div>
                <span className="font-bold underline decoration-orange-400 underline-offset-2">
                  Compartir Recursos
                </span>
                : Nos centramos en identificar y crear enlaces entre recursos,
                programas, personas y comunidades para promover el acceso
                equitativo a oportunidades educativas, laborales, de desarrollo
                personal y comunitario.
              </div>
            </div>
            <div className="flex flex-grow-0 justify-start w-full">
              <button
                className="bg-orange-100 text-orange-800 hover:bg-opacity-80 flex-grow-0 px-8 py-2 rounded-xl mt-4"
                onClick={() => navigate("/nominate")}
              >
                Donate
              </button>
            </div>
          </div>
          <div className="flex relative w-full lg:w-1/2 h-full justify-center items-center overflow-hidden">
            <div className="absolute inset-0  bg-orange-800 z-10 opacity-30 mix-blend-multiply" />
            <img
              className="absolute inset-0 h-full w-full object-cover"
              src={require("../Assets/img/dr-girl.jpeg")}
            />
          </div>
        </Section>
      </div>
    </div>
  );
};

export default Home;
