import React from "react";
import { useNavigate } from "react-router-dom";

const NotFound = () => {
  const navigate = useNavigate();
  return (
    <div className="w-full flex flex-col p-8 text-black justify-center items-center">
      <div>
        <h1 className="font-bold">Hello,</h1>
        <p>Thank you for your interest</p>
        <a
          type="mail"
          href="mailto:lvargass@u.rochester.edu"
          className="text-orange-700"
        >
          Kindly email your request to lvargass@u.rochester.edu
        </a>
      </div>
    </div>
  );
};

export default NotFound;
